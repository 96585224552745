import { PersonalInfoSettingsAPIResponse } from 'api/merchant/dashboard/settings/business-profile.types';
import * as s from 'assets/styles/Platforms.styles';
import { AustralianStates, NewZealandRegions } from 'config/constants';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, RefObject, useEffect, useRef, useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import scrollToComponent from 'react-scroll-to-component';
import * as Yup from 'yup';

import { Button, Heading, Link, Message, Modal, Select, Text, TextField } from '@limepayments/cosmic';

import { SettingBusinessProfileModal, SettingBusinessProfilePayload } from '../types';

const validationSchema = Yup.object().shape({
  contactPerson: Yup.object().shape({
    firstName: Yup.string().trim().required('This field is required'),
    lastName: Yup.string().trim().required('This field is required'),
    phoneNo: Yup.string().trim().required('This field is required'),
  }),
  contactEmail: Yup.string().trim().email('Please enter valid email address').required('This field is required'),
  phoneNo: Yup.string()
    .trim()
    .required('This field is required')
    .test({
      name: 'phone-no-validation',
      test: function () {
        const { phoneNo } = this.parent;
        if (phoneNo && !isPossiblePhoneNumber(phoneNo))
          return this.createError({
            message: `Phone Number is invalid`,
            path: `phoneNo`,
          });
        return true;
      },
    }),
  businessAddress: Yup.string().when('isManualEnterAddress', {
    is: false,
    then: Yup.string().trim().required('This field is required'),
  }),
  city: Yup.string()
    .when('country', {
      is: 'NZ',
      then: Yup.string().nullable().trim().required('This field is required'),
    })
    .nullable(),
  state: Yup.string()
    .when('country', {
      is: 'AU',
      then: Yup.string().nullable().trim().required('This field is required'),
    })
    .nullable(),
  postalCode: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Only digits are allowed for this field'),
  addressLine1: Yup.string().trim().required('This field is required'),
  addressLine2: Yup.string().trim().required('This field is required'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  businessProfileDetail: SettingBusinessProfilePayload;
  personalInfo: PersonalInfoSettingsAPIResponse;
  onSubmitClick: (values: SettingBusinessProfileModal, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBusinessProfileModal>>;
  errorMsg: string;
  editRequestLoading: boolean;
  businessCountry: string;
}

const getInitialState = () => {
  return {
    contactPerson: {
      firstName: '',
      lastName: '',
      phoneNo: '',
    },
    addressLine1: null,
    addressLine2: null,
    brandName: null,
    businessName: null,
    cardStatementName: null,
    city: null,
    contactEmail: null,
    phoneNo: null,
    postalCode: null,
    state: null,
    supportEmail: null,
    supportSite: null,
    website: null,
  };
};

function EditBusinessDetailsModal({
  isOpen,
  businessProfileDetail,
  personalInfo,
  formRef,
  errorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
  businessCountry,
}: Props) {
  const [initialValues, setInitialValues] = useState<SettingBusinessProfileModal>(getInitialState());

  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      scrollToComponent(errorContainerRef.current, { duration: 500 });
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        ...businessProfileDetail,
        contactPerson: personalInfo,
      });
    }
  }, [isOpen, businessProfileDetail, personalInfo]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Business contact details
              </Heading>
            </div>
          </div>
          <Formik<SettingBusinessProfileModal>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              console.log('here');
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full lp-flex two-col mb-16">
                    <div className="lp-w-half w-200">
                      <TextField
                        containerId="standard"
                        label="First name"
                        inputMode="text"
                        testId="business-profile-edit-first-name"
                        autoComplete="off"
                        id="contactPerson.firstName"
                        name="contactPerson.firstName"
                        onBlur={handleBlur}
                        value={values.contactPerson.firstName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.firstName &&
                          touched.contactPerson &&
                          touched.contactPerson.firstName
                            ? errors.contactPerson.firstName
                            : ''
                        }
                      />
                    </div>
                    <div className="lp-w-half w-200">
                      <TextField
                        containerId="standard"
                        label="Last name"
                        inputMode="text"
                        autoComplete="off"
                        testId="business-profile-edit-last-name"
                        id="contactPerson.lastName"
                        name="contactPerson.lastName"
                        onBlur={handleBlur}
                        value={values.contactPerson.lastName}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.lastName &&
                          touched.contactPerson &&
                          touched.contactPerson.lastName
                            ? errors.contactPerson.lastName
                            : ''
                        }
                      />
                    </div>
                  </div>

                  <div className="lp-w-full lp-flex mb-16">
                    <div className="lp-w-full w-200">
                      <TextField
                        containerId="standard"
                        label="Job title (TBC)"
                        inputMode="text"
                        autoComplete="off"
                        id="contactPerson.title"
                        name="contactPerson.title"
                        onBlur={handleBlur}
                        value={values.contactPerson.phoneNo}
                        onChange={handleChange}
                        error={
                          errors.contactPerson &&
                          errors.contactPerson.phoneNo &&
                          touched.contactPerson &&
                          touched.contactPerson.phoneNo
                            ? errors.contactPerson.phoneNo
                            : ''
                        }
                      />
                    </div>
                  </div>

                  <div className="lp-w-full lp-flex w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Contact email address"
                      inputMode="email"
                      type="email"
                      autoComplete="off"
                      id="contactEmail"
                      name="contactEmail"
                      testId="business-profile-edit-email"
                      onBlur={handleBlur}
                      value={values.contactEmail}
                      onChange={handleChange}
                      error={errors.contactEmail && touched.contactEmail ? errors.contactEmail : ''}
                    />
                  </div>

                  <div
                    className={`lp-w-full lp-flex w-200 mb-16${errors.phoneNo && touched.phoneNo ? ` has-error` : ``}`}
                  >
                    <div className={`form-floating no-pl`}>
                      <PhoneInput
                        defaultCountry="AU"
                        value={values.phoneNo ?? ''}
                        onChange={(e) => {
                          setFieldValue('phoneNo', e);
                        }}
                        onBlur={handleBlur}
                        id="phoneNo"
                        name="phoneNo"
                        className={`${values.phoneNo ? `is-filled` : ``}`}
                        countries={['AU', 'NZ']}
                      />
                      <label htmlFor="floatingPhoneInput">Contact phone number</label>
                    </div>
                    <ErrorMessage name="phoneNo" component="span" className="Mui-error" />
                  </div>

                  {!values.isManualEnterAddress && (
                    <Fragment>
                      <div className="lp-w-full mb-16">
                        <div className="lp-w-full w-200 mb-4">
                          <TextField
                            containerId="standard"
                            label="Business address"
                            inputMode="text"
                            autoComplete="off"
                            id="businessAddress"
                            name="businessAddress"
                            onBlur={handleBlur}
                            value={values.businessAddress}
                            onChange={handleChange}
                            error={errors.businessAddress && touched.businessAddress ? errors.businessAddress : ''}
                            helperText="You can use a home address if the business doesn't have a business address."
                          />
                        </div>
                      </div>
                      <Link
                        onClick={() => setFieldValue('isManualEnterAddress', true)}
                        size="small"
                        target="_self"
                        className={`fw-medium ${values.isManualEnterAddress ? `link-hidden` : ``}`}
                      >
                        Manually enter address
                      </Link>
                    </Fragment>
                  )}

                  {values.isManualEnterAddress && (
                    <div className="lp-w-full mt-24">
                      <div className="lp-w-full mb-16">
                        <Heading alignment="left" tagName="h2" variant="xs">
                          Business address
                        </Heading>
                        <Text variant="body-3" className="text-dark-50 mt-4">
                          You can use a home address if the business doesn't have a business address.
                        </Text>
                      </div>

                      <div className={`lp-w-full w-200 mb-16`}>
                        <Select
                          label="Country (TBC)"
                          onChange={function noRefCheck() {}}
                          options={[
                            {
                              text: 'Australia',
                              value: 'AU',
                            },
                            {
                              text: 'New Zealand',
                              value: 'NZ',
                            },
                          ]}
                          testId="country"
                          value=""
                        />
                      </div>

                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Unit number (optional) (TBC)"
                          onBlur={function noRefCheck() {}}
                          onChange={function noRefCheck() {}}
                          onFocus={function noRefCheck() {}}
                          onKeyDown={function noRefCheck() {}}
                          onKeyPress={function noRefCheck() {}}
                          onKeyUp={function noRefCheck() {}}
                          onMouseDown={function noRefCheck() {}}
                          onMouseLeave={function noRefCheck() {}}
                          onMouseOver={function noRefCheck() {}}
                          value=""
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Street number"
                          inputMode="text"
                          autoComplete="off"
                          id="addressLine1"
                          name="addressLine1"
                          onBlur={handleBlur}
                          value={values.addressLine1}
                          onChange={handleChange}
                          error={errors.addressLine1 && touched.addressLine1 ? errors.addressLine1 : ''}
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Street name"
                          inputMode="text"
                          autoComplete="off"
                          id="addressLine2"
                          name="addressLine2"
                          onBlur={handleBlur}
                          value={values.addressLine2}
                          onChange={handleChange}
                          error={errors.addressLine2 && touched.addressLine2 ? errors.addressLine2 : ''}
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <Select
                          label="Street type (TBC)"
                          onChange={function noRefCheck() {}}
                          options={[
                            {
                              text: 'Australia',
                              value: 'AU',
                            },
                            {
                              text: 'New Zealand',
                              value: 'NZ',
                            },
                          ]}
                          testId="streettype"
                          value=""
                        />
                      </div>
                      <div className="lp-w-full w-200 mb-16">
                        <TextField
                          fullWidth
                          label="Suburb (TBC)"
                          onBlur={function noRefCheck() {}}
                          onChange={function noRefCheck() {}}
                          onFocus={function noRefCheck() {}}
                          onKeyDown={function noRefCheck() {}}
                          onKeyPress={function noRefCheck() {}}
                          onKeyUp={function noRefCheck() {}}
                          onMouseDown={function noRefCheck() {}}
                          onMouseLeave={function noRefCheck() {}}
                          onMouseOver={function noRefCheck() {}}
                          value=""
                        />
                      </div>
                      <div className={`lp-w-full w-200 mb-16${errors.city && touched.city ? ` has-error` : ``}`}>
                        {businessCountry === 'NZ' && (
                          <Select
                            label="City"
                            onChange={(value) => setFieldValue('city', value)}
                            value={values.city ?? ''}
                            options={NewZealandRegions}
                            errorMessage={errors.city && touched.city ? errors.city : ''}
                          />
                        )}
                      </div>
                      <div className={`lp-w-full w-200 mb-16${errors.state && touched.state ? ` has-error` : ``}`}>
                        {businessCountry === 'AU' && (
                          <Select
                            label="State"
                            onChange={(value) => setFieldValue('state', value)}
                            value={values.state ?? ''}
                            options={AustralianStates}
                            errorMessage={errors.state && touched.state ? errors.state : ''}
                          />
                        )}
                      </div>
                      <div className="lp-w-full w-200 mb-0">
                        <TextField
                          fullWidth
                          label="Postcode"
                          inputMode="text"
                          autoComplete="off"
                          id="postalCode"
                          name="postalCode"
                          onBlur={handleBlur}
                          value={values.postalCode}
                          onChange={handleChange}
                          error={errors.postalCode && touched.postalCode ? errors.postalCode : ''}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    onClick={() => modalToggler(false)}
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width w-70"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default EditBusinessDetailsModal;
