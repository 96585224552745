export const RoutesList = {
  MERCHANT_ROUTE: 'merchant',
  PLATFORM_ROUTE: 'platform',
  AUTH_ROUTE: 'auth',
  BILLING_ROUTE: 'billing',
};

export const ApplicationAreaOption = {
  merchant: 'merchant',
  merchant_dashboard: 'merchant-dashboard',
  platform: 'platform',
};

export const StreetTypes = [
  { value: 'Alley', text: 'Alley' },
  { value: 'Arcade', text: 'Arcade' },
  { value: 'Avenue', text: 'Avenue' },
  { value: 'Boulevard', text: 'Boulevard' },
  { value: 'Bypass', text: 'Bypass' },
  { value: 'Circuit', text: 'Circuit' },
  { value: 'Close', text: 'Close' },
  { value: 'Corner', text: 'Corner' },
  { value: 'Court', text: 'Court' },
  { value: 'Crescent', text: 'Crescent' },
  { value: 'Cul - de - sac', text: 'Cul - de - sac' },
  { value: 'Drive', text: 'Drive' },
  { value: 'Esplanade', text: 'Esplanade' },
  { value: 'Green', text: 'Green' },
  { value: 'Grove', text: 'Grove' },
  { value: 'Highway', text: 'Highway' },
  { value: 'Junction', text: 'Junction' },
  { value: 'Lane', text: 'Lane' },
  { value: 'Link', text: 'Link' },
  { value: 'Mews', text: 'Mews' },
  { value: 'Parade', text: 'Parade' },
  { value: 'Place', text: 'Place' },
  { value: 'Ridge', text: 'Ridge' },
  { value: 'Road', text: 'Road' },
  { value: 'Square', text: 'Square' },
  { value: 'Street', text: 'Street' },
  { value: 'Terrace', text: 'Terrace' },
];

export const AustralianStates = [
  { value: 'ACT', text: 'Australian Capital Territory' },
  { value: 'NSW', text: 'New South Wales' },
  { value: 'NT', text: 'Northern Territory' },
  { value: 'QLD', text: 'Queensland' },
  { value: 'SA', text: 'South Australia' },
  { value: 'TAS', text: 'Tasmania' },
  { value: 'VIC', text: 'Victoria' },
  { value: 'WA', text: 'Western Australia' },
];

export const NewZealandRegions = [
  { value: 'Auckland', text: 'Auckland' },
  { value: 'Bay of Plenty', text: 'Bay of Plenty' },
  { value: 'Canterbury', text: 'Canterbury' },
  { value: 'Gisborne', text: 'Gisborne' },
  { value: "Hawke's Bay", text: "Hawke's Bay" },
  { value: 'Manawatu-Wanganui', text: 'Manawatu-Wanganui' },
  { value: 'Marlborough', text: 'Marlborough' },
  { value: 'Nelson', text: 'Nelson' },
  { value: 'Northland', text: 'Northland' },
  { value: 'Otago', text: 'Otago' },
  { value: 'Southland', text: 'Southland' },
  { value: 'Taranaki', text: 'Taranaki' },
  { value: 'Tasman', text: 'Tasman' },
  { value: 'Waikato', text: 'Waikato' },
  { value: 'Wellington', text: 'Wellington' },
  { value: 'West Coast', text: 'West Coast' },
];

export const ausStateObj = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

export default RoutesList;
