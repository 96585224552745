import { BrandingAPIResponse } from 'api/merchant/dashboard/settings/branding.types';
import { FormikProps } from 'formik';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue, Link, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import EditBrandingModal from './EditBrandingModal';

export interface Props {
  brandingDetail: BrandingAPIResponse;
  onSubmitClick: (values: BrandingAPIResponse, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<BrandingAPIResponse>>;
  errorMsg: string;
  editRequestLoading: boolean;
  brandEditModalOpen: boolean;
  setBrandEditModalOpen: (val: boolean) => void;
  editModal?: boolean;
  isPageLoading: boolean;
  errorMessage: string;
}

function BrandingSettingView({
  brandingDetail,
  brandEditModalOpen,
  onSubmitClick,
  setBrandEditModalOpen,
  formRef,
  errorMsg,
  editRequestLoading,
  isPageLoading,
  errorMessage,
  editModal,
}: Props) {
  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_BRANDING} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Branding
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full mb-32">
                    <Heading alignment="left" tagName="h2" variant="xs">
                      Customer facing
                    </Heading>
                    <Text variant="body-3" className="mt-4">
                      Branding your customers will see as they interact or transact with your business.
                    </Text>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-start lp-flex-row sm-lp-flex-col mb-24">
                    <div className="avatar avatar-100"></div>
                    <div className="branding-details">
                      <div className="lp-w-full mb-24">
                        <LabelledValue label="Trading name" value="Laddr (TBC)" variant="vertical" />
                      </div>
                      <div className="lp-w-full mb-0">
                        <div className="lp-flex lp-align-center ">
                          <Text variant="caption" className="text-dark-50 m-0">
                            Support email
                          </Text>
                          <span
                            data-balloon="The support email your merchants' customers are directed to for help."
                            data-balloon-pos="up"
                            data-balloon-length="large"
                            className="lp-flex lp-align-center lp-justify-center size-11"
                          >
                            <Icon name="Info" />
                          </span>
                        </div>
                        <Text variant="body-3" className="mt-0">
                          support@limepay.zendesk.com (TBC)
                        </Text>
                      </div>
                    </div>
                  </div>
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Branding details
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Branding details representing your business.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      <Button
                        size="small"
                        variant="reversed"
                        onClick={() => setBrandEditModalOpen(!brandEditModalOpen)}
                      >
                        Edit
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Trading name" value="Fisher's Plumbing" variant="vertical" />
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Logo URL
                      </Text>
                      <span
                        data-balloon="Convert your logo to a URL by uploading it to an image hosting website.
For best result use a .jpg or .png image URL with dimensions 175x175 pixels."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      {brandingDetail.logoUri}
                    </Text>
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Favicon URL
                      </Text>
                      <span
                        data-balloon="Convert your favicon to a URL by uploading it to an image hosting website. 
For best result use a .ico or .png image URL with dimensions 32x32 or 16x16 pixels."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      {brandingDetail.iconUri}
                    </Text>
                  </div>

                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Logo alt text" value={brandingDetail.logoAltText ?? ''} variant="vertical" />
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Card statement name
                      </Text>
                      <span
                        data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      FPlumbing (TBC)
                    </Text>
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        SMS sender name
                      </Text>
                      <span
                        data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      FPlumbing (TBC)
                    </Text>
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Payout description
                      </Text>
                      <span
                        data-balloon="The description of bank statements in merchants payouts."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      April (TBC)
                    </Text>
                  </div>

                  <div className="lp-w-full mb-32">
                    <div className="lp-flex lp-align-center ">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Business website
                      </Text>
                    </div>
                    <Link onClick={function noRefCheck() {}} size="small" target="_self" className="fw-medium">
                      www.fishersplumbing.com (TBC)
                    </Link>
                  </div>
                </s.MerchantsData>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <EditBrandingModal
        isOpen={editModal || brandEditModalOpen}
        modalToggler={setBrandEditModalOpen}
        initialValues={JSON.parse(JSON.stringify(brandingDetail))}
        onSubmitClick={onSubmitClick}
        formRef={formRef}
        errorMsg={errorMsg}
        editRequestLoading={editRequestLoading}
      />
    </Fragment>
  );
}

export default BrandingSettingView;
