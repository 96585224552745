import { BrandingAPIResponse } from 'api/merchant/dashboard/settings/branding.types';
import * as s from 'assets/styles/Platforms.styles';
import { Formik, FormikProps } from 'formik';
import { RefObject, useEffect, useRef } from 'react';
import * as Yup from 'yup';

import { Button, Heading, Icon, Modal, TextField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  iconUri: Yup.string().trim().required('This field is required').url('Please enter valid url'),
  logoUri: Yup.string().trim().required('This field is required').url('Please enter valid url'),
  logoAltText: Yup.string().trim().required('This field is required'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (val: boolean) => void;
  initialValues: BrandingAPIResponse;
  onSubmitClick: (values: BrandingAPIResponse, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<BrandingAPIResponse>>;
  errorMsg: string;
  editRequestLoading: boolean;
}

function EditBrandingModal({
  isOpen,
  initialValues,
  formRef,
  errorMsg,
  editRequestLoading,
  modalToggler,
  onSubmitClick,
}: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Branding details
              </Heading>
            </div>
          </div>
          <Formik<BrandingAPIResponse>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              onSubmitClick(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  <div className="lp-w-full is-row-disabled">
                    <div className="lp-w-full w-200  mb-4">
                      <TextField
                        containerId="standard"
                        label="Trading name"
                        onBlur={function noRefCheck() {}}
                        onChange={function noRefCheck() {}}
                        onFocus={function noRefCheck() {}}
                        onKeyDown={function noRefCheck() {}}
                        onKeyPress={function noRefCheck() {}}
                        onKeyUp={function noRefCheck() {}}
                        onMouseDown={function noRefCheck() {}}
                        onMouseLeave={function noRefCheck() {}}
                        onMouseOver={function noRefCheck() {}}
                        value="Fisher's Plumbing"
                      />
                    </div>
                  </div>
                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Logo URL"
                      testId="branding-edit-logo-url"
                      id="logoUri"
                      name="logoUri"
                      value={values.logoUri}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.logoUri && touched.logoUri ? errors.logoUri : ''}
                      endAdornment={
                        <span
                          data-balloon="For best result use image dimentions 175 pixels wide x 100 pixels high and convert your image to a link from a .jpg or .png format."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="Use a direct link ending with .jpg or .png"
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Favicon URL"
                      testId="branding-edit-favicon-url"
                      id="iconUri"
                      name="iconUri"
                      value={values.iconUri}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.iconUri && touched.iconUri ? errors.iconUri : ''}
                      endAdornment={
                        <span
                          data-balloon="Convert your favicon to a URL by uploading it to an image hosting website. 
For best result use a .ico or .png image URL with dimensions 32x32 or 16x16 pixels."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      helperText="Use a direct link ending with .ico or .png"
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Logo alt text"
                      testId="branding-edit-logo-alt-text"
                      id="logoAltText"
                      name="logoAltText"
                      value={values.logoAltText}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.logoAltText && touched.logoAltText ? errors.logoAltText : ''}
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Payout description"
                      onBlur={function noRefCheck() {}}
                      onChange={function noRefCheck() {}}
                      onFocus={function noRefCheck() {}}
                      onKeyDown={function noRefCheck() {}}
                      onKeyPress={function noRefCheck() {}}
                      onKeyUp={function noRefCheck() {}}
                      onMouseDown={function noRefCheck() {}}
                      onMouseLeave={function noRefCheck() {}}
                      onMouseOver={function noRefCheck() {}}
                      endAdornment={
                        <span
                          data-balloon="The description of bank statements in merchants payouts."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      value="April"
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="Card statement name"
                      onBlur={function noRefCheck() {}}
                      onChange={function noRefCheck() {}}
                      onFocus={function noRefCheck() {}}
                      onKeyDown={function noRefCheck() {}}
                      onKeyPress={function noRefCheck() {}}
                      onKeyUp={function noRefCheck() {}}
                      onMouseDown={function noRefCheck() {}}
                      onMouseLeave={function noRefCheck() {}}
                      onMouseOver={function noRefCheck() {}}
                      endAdornment={
                        <span
                          data-balloon="The business name that appears on customer bank statements. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      value="FPlumbing"
                      helperText="5-13 alphabetic characters only, this includes upper and lower cases."
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-24">
                    <TextField
                      fullWidth
                      label="SMS sender name"
                      onBlur={function noRefCheck() {}}
                      onChange={function noRefCheck() {}}
                      onFocus={function noRefCheck() {}}
                      onKeyDown={function noRefCheck() {}}
                      onKeyPress={function noRefCheck() {}}
                      onKeyUp={function noRefCheck() {}}
                      onMouseDown={function noRefCheck() {}}
                      onMouseLeave={function noRefCheck() {}}
                      onMouseOver={function noRefCheck() {}}
                      endAdornment={
                        <span
                          data-balloon="The contact name that appears on SMS received by customers regarding their payment. A recognisable name is important so that customers remember where they purchased from and to avoid unintended disputes."
                          data-balloon-pos="left"
                          data-balloon-length="large"
                          className="size-16"
                        >
                          <Icon className="text-primary" name="Info" />
                        </span>
                      }
                      value="FPlumbing"
                      helperText="3-11 alphabetic or numeric characters, this includes lower or uppercase letters."
                    />
                  </div>

                  <div className="lp-w-full w-200 mb-16">
                    <TextField
                      containerId="standard"
                      label="Business website"
                      onBlur={function noRefCheck() {}}
                      onChange={function noRefCheck() {}}
                      onFocus={function noRefCheck() {}}
                      onKeyDown={function noRefCheck() {}}
                      onKeyPress={function noRefCheck() {}}
                      onKeyUp={function noRefCheck() {}}
                      onMouseDown={function noRefCheck() {}}
                      onMouseLeave={function noRefCheck() {}}
                      onMouseOver={function noRefCheck() {}}
                      value="www.fishersplumbing.com"
                    />
                  </div>
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    testId="branding-edit-cancel-button"
                    disabled={editRequestLoading}
                    onClick={() => modalToggler(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width w-70"
                    testId="branding-edit-submit-button"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Save
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default EditBrandingModal;
