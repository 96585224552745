import {
  BusinessInfoSettingsAPIResponse,
  PersonalInfoSettingsAPIResponse,
  PublicInfoAPIResponseType,
} from 'api/merchant/dashboard/settings/business-profile.types';
import { FormikProps } from 'formik';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment, RefObject } from 'react';

import { Button, Heading, Icon, LabelledValue, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';
import { SettingBusinessProfileModal } from '../types';
import EditBusinessDetailsModal from './EditBusinessDetailsModal';

export interface Props {
  businessProfileDetail: PublicInfoAPIResponseType;
  personalInfo: PersonalInfoSettingsAPIResponse;
  onSubmitClick: (values: SettingBusinessProfileModal, formObj: { resetForm: () => void }) => void;
  formRef: RefObject<FormikProps<SettingBusinessProfileModal>>;
  errorMsg: string;
  editRequestLoading: boolean;
  editModalOpen: boolean;
  setEditModalOpen: (val: boolean) => void;
  platformRegistationDetail: BusinessInfoSettingsAPIResponse;
  isPageLoading: boolean;
  errorMessage: string;
  businessDetailModalShow?: boolean;
}

function BusinessProfileSettingView({
  businessProfileDetail,
  personalInfo,
  onSubmitClick,
  formRef,
  errorMsg,
  editModalOpen,
  editRequestLoading,
  setEditModalOpen,
  platformRegistationDetail,
  isPageLoading,
  errorMessage,
  businessDetailModalShow,
}: Props) {
  const getAddress = () => {
    let returnData = ``;

    if (businessProfileDetail.addressLine1) {
      returnData += `${businessProfileDetail.addressLine1}, `;
    }

    if (businessProfileDetail.addressLine2) {
      returnData += `${businessProfileDetail.addressLine2} `;
    }

    returnData += businessProfileDetail.city ? `${businessProfileDetail.city}` : `${businessProfileDetail.state}`;
    returnData += ` - ${businessProfileDetail.postalCode}`;

    return returnData;
  };

  return (
    <Fragment>
      <div className="content-wrap-inner with-gutter">
        <div className="lp-w-full lp-flex">
          <div className="payment-sidebar">
            <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_BUSINESS_PROFILE} />
          </div>

          <div className="payment-content-area">
            {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

            {isPageLoading && (
              <div className="spinner-wrapper">
                <Spinner variant="simple" isVisible label="Loading..." />
              </div>
            )}

            {!isPageLoading && !errorMessage.length && (
              <Fragment>
                <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                  <Heading alignment="left" tagName="h2" variant="sm">
                    Business profile
                  </Heading>
                </div>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                    <div>
                      <Heading alignment="left" tagName="h2" variant="xs">
                        Business contact details
                      </Heading>
                      <Text variant="body-3" className="mt-4">
                        Contact details for ongoing engagements.
                      </Text>
                    </div>
                    <div className="ml-auto">
                      <Button size="small" variant="reversed" onClick={() => setEditModalOpen(!editModalOpen)}>
                        Edit
                        <Icon name="Edit" />
                      </Button>
                    </div>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="First name" value={personalInfo.firstName ?? ''} variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Last name" value={personalInfo.lastName ?? ''} variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Job title" value={'Director (TBC)'} variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Contact email address"
                      value={businessProfileDetail.contactEmail ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Contact phone number"
                      value={businessProfileDetail.phoneNo ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Business address" value={getAddress()} variant="vertical" />
                  </div>
                </s.MerchantsData>

                <s.MerchantsData className="bt-1">
                  <div className="lp-w-full mb-32">
                    <Heading alignment="left" tagName="h2" variant="xs">
                      Business registration details
                    </Heading>
                    <Text variant="body-3" className="mt-4">
                      The official details of your business.
                    </Text>
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue label="Tax ID" value={platformRegistationDetail.taxId ?? ''} variant="vertical" />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Legal business name"
                      value="Fisher's Plumbing PTY LTD (TBC)"
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Business type"
                      value={platformRegistationDetail.businessType ?? ''}
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-32">
                    <LabelledValue
                      label="Business industry"
                      value="101 Plumbing and maintenence (TBC)"
                      variant="vertical"
                    />
                  </div>
                  <div className="lp-w-full mb-0">
                    <div className="lp-flex lp-align-center">
                      <Text variant="caption" className="text-dark-50 m-0">
                        Supported customer types
                      </Text>
                      <span
                        data-balloon="Consumers and businesses have different eligibility checks for payment plans."
                        data-balloon-pos="up"
                        data-balloon-length="large"
                        className="lp-flex lp-align-center lp-justify-center size-11"
                      >
                        <Icon name="Info" />
                      </span>
                    </div>
                    <Text variant="body-3" className="mt-0">
                      Businesses (B2C) (TBC)
                    </Text>
                    <Text variant="body-3" className="mt-0">
                      Businesses (B2B) (TBC)
                    </Text>
                  </div>
                </s.MerchantsData>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <EditBusinessDetailsModal
        isOpen={businessDetailModalShow ?? editModalOpen}
        modalToggler={setEditModalOpen}
        businessProfileDetail={JSON.parse(JSON.stringify(businessProfileDetail))}
        personalInfo={JSON.parse(JSON.stringify(personalInfo))}
        onSubmitClick={onSubmitClick}
        formRef={formRef}
        errorMsg={errorMsg}
        editRequestLoading={editRequestLoading}
        businessCountry={platformRegistationDetail.country}
      />
    </Fragment>
  );
}

export default BusinessProfileSettingView;
