import { getSettingBrandingDetail, updateSettingBrandingDetail } from 'api/merchant/dashboard/settings/branding';
import { BrandingAPIResponse } from 'api/merchant/dashboard/settings/branding.types';
import { FormikProps } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import BrandingSettingView from './partials/BrandingSettingView';

export const getInitialBrandInfo = (): BrandingAPIResponse => ({
  iconUri: '',
  logoUri: '',
  logoAltText: '',
});

function BrandingSettingPage() {
  const [fetchBrandingDetailLoader, setFetchBrandingDetailLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [brandingDetail, setBrandingDetail] = useState<BrandingAPIResponse>(getInitialBrandInfo());
  const formRef = useRef<FormikProps<BrandingAPIResponse>>(null);
  const [brandEditModalOpen, setBrandEditModalOpen] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const submitHandler = async (values: BrandingAPIResponse, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const { ...postData } = { ...values };

      await updateSettingBrandingDetail(apiBaseUri, merchantId, postData);

      toast.success('Branding details updated successfully');
      formObj.resetForm();
      setBrandEditModalOpen(false);
      fetchBrandingDetail();
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchBrandingDetail = useCallback(async () => {
    try {
      setFetchBrandingDetailLoader(true);
      setIsPageLoading(true);

      const response = await getSettingBrandingDetail(apiBaseUri, merchantId);

      setBrandingDetail(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBrandingDetailLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    if (!merchantId) {
      return;
    }

    setErrorMessage('');
    fetchBrandingDetail();
  }, [apiBaseUri, merchantId, fetchBrandingDetail]);

  useEffect(() => {
    if (!fetchBrandingDetailLoader) {
      setIsPageLoading(false);
    }
  }, [fetchBrandingDetailLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Branding Settings">
      <BrandingSettingView
        errorMsg={updateErrorMsg}
        editRequestLoading={editRequestLoading}
        brandingDetail={brandingDetail}
        onSubmitClick={submitHandler}
        formRef={formRef}
        setBrandEditModalOpen={setBrandEditModalOpen}
        brandEditModalOpen={brandEditModalOpen}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
      />
    </MerchantDashboardLayout>
  );
}

export default BrandingSettingPage;
