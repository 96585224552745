import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import { BrandingAPIResponse } from './branding.types';

export const getSettingBrandingDetail = async (host: string, merchantId: string): Promise<BrandingAPIResponse> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/settings/branding`, options).then(handleResponse);
};

export const updateSettingBrandingDetail = async (
  host: string,
  merchantId: string,
  payload: BrandingAPIResponse,
): Promise<BrandingAPIResponse> => {
  const options = await getFetchOptions('POST', { payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/branding`, options).then(handleResponse);
};
