import { PayoutAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import * as s from 'assets/styles/Platforms.styles';
import DashboardRoutesList from 'pages/merchant/dashboard/constants';
import { useMemo, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppSelector } from 'redux/merchant/hooks';
import { CountryCurrency, toCurrency } from 'utils/currency';

import { Button, FlexContainer, Heading, Icon, LabelledValue } from '@limepayments/cosmic';

import PayoutSidebar from '../../partials/PayoutSidebar';
import { IPayoutFilterObj, IPayoutFilterParentObj, IPayoutFilterValuesObj } from '../types';
// Import Static UIs
import FilterComponent from './FilterComponent';
import PayoutDataTable from './PayoutDataTable';

const getInitialFilterState = () => {
  return {
    dateValue: '',
    dateTypeCompareValue: '',
    dateTypeFilterValue: '',
    statusValue: '',
    dateRangeValue: '',
    dateSingleValue: null,
    saveClick: false,
    dateActive: false,
    statusFilterApply: false,
    merchantIdSelected: false,
    txnIdSelected: false,
    orderIdSelected: false,
    amountIdSelected: false,
    merchantId: '',
    orderId: '',
    txnId: '',
    amount: '',
    customerNameSelected: false,
    customerName: '',
    paymentOptionSelected: false,
    paymentOption: '',
  };
};

export interface Props {
  balances: GetMerchantBalancesResponse['balances'];
  payoutList: Array<PayoutAPIResponseType>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  toggleFilter: (val: boolean) => void;
  filterRecordHandler: (obj: IPayoutFilterValuesObj) => void;
  filterValuesObj: IPayoutFilterParentObj;
  activePage: number;
  handleSort: (selectedColumn: TableColumn<PayoutAPIResponseType>, sortDirection: SortOrder) => void;
  isFilterRequest?: boolean;
  isErrorRequest?: boolean;
}

function PayoutBalancePage({
  balances,
  payoutList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  toggleFilter,
  filterRecordHandler,
  filterValuesObj,
  activePage,
  handleSort,
  isFilterRequest,
  isErrorRequest,
}: Props) {
  // filter section variables
  const [filterValueObj, setFilterValueObj] = useState<IPayoutFilterObj>(getInitialFilterState());

  const { config } = useAppSelector((state) => ({
    config: state.config,
  }));

  const balance = useMemo(() => {
    return balances?.find(({ currency }) => currency === CountryCurrency[config.merchantTradingCountry]) ?? null;
  }, [config, balances]);

  const getFilterApplyCount = () => {
    let returnCount = 0;

    if (filterValueObj.dateActive && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.statusFilterApply && filterValueObj.saveClick) {
      returnCount++;
    }

    if (filterValueObj.merchantIdSelected && filterValueObj.merchantId) {
      returnCount++;
    }

    if (filterValueObj.orderIdSelected && filterValueObj.orderId) {
      returnCount++;
    }

    if (filterValueObj.txnIdSelected && filterValueObj.txnId) {
      returnCount++;
    }

    if (filterValueObj.amountIdSelected && filterValueObj.amount) {
      returnCount++;
    }

    return returnCount;
  };

  const toggleFilterHandler = (val: boolean, closeSection?: boolean) => {
    if (val) {
      resetFilterValuesHandler();
    }

    if (closeSection) {
      toggleFilter(val);
    }
  };

  const resetFilterValuesHandler = () => {
    setFilterValueObj(getInitialFilterState());
  };

  return (
    <div className="content-wrap-inner lp-full m-0">
      <div className="lp-w-full lp-flex">
        <PayoutSidebar activeTab={DashboardRoutesList.PAYOUT_ROUTE} />

        <div className="payment-content-area">
          <Heading alignment="left" tagName="h2" variant="sm" className="mb-16">
            Payouts
          </Heading>
          <div className="lp-w-full lp-flex lp-justify-between lp-align-end sm:flex-col">
            <s.WellWrap className="inline m-0">
              <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-0 lp-align-end">
                <div className="lp-flex lp-flex-row">
                  <div className="lp-flex lp-flex-row labelled-value mr-32">
                    <LabelledValue
                      label="Pending"
                      value={balance?.pending !== undefined ? toCurrency(balance.pending, balance.currency) : '-'}
                      variant="vertical"
                      size="large"
                    />
                  </div>
                  <div className="lp-flex lp-flex-row labelled-value mr-32">
                    <LabelledValue
                      label="Available"
                      value={balance?.available !== undefined ? toCurrency(balance.available, balance.currency) : '-'}
                      variant="vertical"
                      size="large"
                    />
                  </div>
                  <div className="lp-flex lp-flex-row labelled-value mr-0">
                    <LabelledValue
                      label="Total balance"
                      value={
                        balance?.pending !== undefined && balance?.available !== undefined
                          ? toCurrency(balance.pending + balance.available, balance.currency)
                          : '-'
                      }
                      variant="vertical"
                      size="large"
                      emphasized
                    />
                  </div>
                </div>
              </div>
            </s.WellWrap>
            <div className="merchant-controls sm-mt-16 sm:lp-full sm:pr-0">
              <FlexContainer classNames="lp-flex m-0">
                <div className="lp-flex order-2 position-relative m-0 mb-0">
                  <Button
                    className="lp-w-full"
                    size="small"
                    variant="reversed"
                    onClick={() => toggleFilter(false)}
                    disabled={payoutList.length < 1 && getFilterApplyCount() === 0}
                  >
                    <Icon name="Filter" className="ml-0 mr-16" />
                    Filter ({getFilterApplyCount()})
                  </Button>

                  {/* Filter Dropdown */}
                  {filterValuesObj.filterPopupOpen && (
                    <div className="filter-dropdown w-328">
                      <FilterComponent onCloseFilterClick={toggleFilterHandler} />
                    </div>
                  )}
                </div>
              </FlexContainer>
            </div>
          </div>

          <PayoutDataTable
            payoutList={payoutList}
            fetchListLoader={fetchListLoader}
            totalRows={totalRows}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            limit={limit}
            activePage={activePage}
            handleSort={handleSort}
            isFilterRequest={isFilterRequest || getFilterApplyCount() > 0}
            isErrorRequest={isErrorRequest}
          />
        </div>
      </div>
    </div>
  );
}

export default PayoutBalancePage;
