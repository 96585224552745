import { updatePaymentConfirmation } from 'api/merchant/dashboard/settings/payment';
import { PaymentConfirmationAPIResponse } from 'api/merchant/dashboard/settings/payment.types';
import * as s from 'assets/styles/Platforms.styles';
import { ErrorMessage, Formik, FormikProps } from 'formik';
import { Fragment, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';
import * as Yup from 'yup';

import { Button, Heading, Icon, Message, Modal, Switch, Text, TextField } from '@limepayments/cosmic';

const validationSchema = Yup.object().shape({
  orderPaidNotificationEmails: Yup.array()
    .of(Yup.string().email('Must be valid email addresses'))
    .min(1, 'Please enter atleast one email'),
  email: Yup.string().email('Please enter a valid email address'),
});

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
  paymentConfirmationObj: PaymentConfirmationAPIResponse;
  refreshDataHandler: () => void;
}

function PaymentConfirmationsModal({ isOpen, modalToggler, paymentConfirmationObj, refreshDataHandler }: Props) {
  const errorContainerRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormikProps<PaymentConfirmationAPIResponse>>(null);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [initialValues, setInitialValues] = useState<PaymentConfirmationAPIResponse>({
    ...paymentConfirmationObj,
    email: '',
  });

  const { apiBaseUri, merchantId } = useAppSelector((state) => ({
    apiBaseUri: state.config.apiBaseUri,
    merchantId: state.config.merchantId,
  }));

  const submitHandler = async (values: PaymentConfirmationAPIResponse, formObj: { resetForm: () => void }) => {
    try {
      const { email, ...postData } = values;

      setEditRequestLoading(true);
      setErrorMsg('');

      await updatePaymentConfirmation(apiBaseUri, merchantId, postData);

      toast.success('Payment confirmation updated successfully');

      formObj.resetForm();
      modalToggler(false);
      refreshDataHandler();
    } catch (error) {
      setErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  useEffect(() => {
    if (errorMsg.length > 0 && errorContainerRef && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView();
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isOpen) {
      setInitialValues({
        ...paymentConfirmationObj,
        email: '',
      });
      setErrorMsg('');
    }
  }, [isOpen, paymentConfirmationObj]);

  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Payment confirmations
              </Heading>
            </div>
          </div>
          <Formik<PaymentConfirmationAPIResponse>
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values, formObj: { resetForm: () => void }) => {
              submitHandler(values, formObj);
            }}
            validationSchema={validationSchema}
            innerRef={formRef}
          >
            {({ values, handleSubmit, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <form autoComplete="off" onSubmit={handleSubmit} className="content-wrap-form">
                <div className="modal-content p-28 sm-p-0 sm-mt-24">
                  {errorMsg.length > 0 && (
                    <div className="mt-24 mb-24" ref={errorContainerRef}>
                      <Message
                        type="inline"
                        children={<span className="text-wrap">{errorMsg}</span>}
                        variant={'error'}
                      />
                    </div>
                  )}

                  <div className="lp-w-full mb-8">
                    <div>
                      <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                        Receive notifications via email
                      </Text>
                      <Text variant="caption" className="text-dark-50 mt-4">
                        After a new transaction is processed, a payment notification will be sent to nominated emails.
                      </Text>
                    </div>
                  </div>
                  <div className="lp-w-full w-200 mb-24">
                    <div
                      className={`lp-flex payment-switch ${
                        errors.orderPaidNotificationsEnabled && touched.orderPaidNotificationsEnabled
                          ? ` has-error`
                          : ``
                      }`}
                    >
                      <Switch
                        className="text-neutral-900"
                        id="orderPaidNotificationsEnabled"
                        label={values.orderPaidNotificationsEnabled ? `Enabled` : `Disabled`}
                        size="small"
                        checked={values.orderPaidNotificationsEnabled ? true : false}
                        onChange={(value) => {
                          setFieldValue('orderPaidNotificationsEnabled', value);
                        }}
                      />
                      <ErrorMessage name="orderPaidNotificationsEnabled" component="span" className="Mui-error" />
                    </div>
                  </div>
                  <div className="lp-w-full mb-24">
                    {!values.orderPaidNotificationsEnabled && (
                      <div>
                        <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                          Recipients
                        </Text>
                        {values.orderPaidNotificationEmails
                          ? values.orderPaidNotificationEmails.map((email, key) => (
                              <Text key={key} variant="body-3" className="text-dark-25 m-0">
                                {email}
                              </Text>
                            ))
                          : null}
                      </div>
                    )}

                    {values.orderPaidNotificationsEnabled && (
                      <Fragment>
                        <div className="lp-flex lp-tags lp-flex-wrap">
                          {values.orderPaidNotificationEmails
                            ? values.orderPaidNotificationEmails.map((email, key) => (
                                <div className="lp-flex lp-align-center tag" key={key}>
                                  <Text variant="body-3" isEmphasised className="text-dark mt-0">
                                    {email}
                                  </Text>
                                  <button
                                    type="button"
                                    className="lp-flex lp-align-center lp-justify-center tag-remove"
                                    onClick={() => {
                                      const emailArray = values.orderPaidNotificationEmails
                                        ? values.orderPaidNotificationEmails.filter((obj) => {
                                            return obj !== email;
                                          })
                                        : [];

                                      setFieldValue('orderPaidNotificationEmails', emailArray);
                                    }}
                                  >
                                    <Icon name="Cross" />
                                  </button>
                                </div>
                              ))
                            : null}
                        </div>
                        <ErrorMessage name="orderPaidNotificationEmails" component="span" className="Mui-error" />
                      </Fragment>
                    )}
                  </div>

                  {values.orderPaidNotificationsEnabled && (
                    <div className="lp-w-full lp-flex lp-align-end lp-justify-between mb-16">
                      <div className="lp-w-full w-200">
                        <TextField
                          fullWidth
                          label="Email"
                          containerId="standard"
                          inputMode="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id="email"
                          name="email"
                          error={errors.email && touched.email ? errors.email : ``}
                        />
                      </div>
                      <div className="lp-w-120 btm-4px ml-auto">
                        <Button
                          type="button"
                          onClick={() => {
                            if (values.email) {
                              const orderPaidNotificationEmails = values.orderPaidNotificationEmails
                                ? values.orderPaidNotificationEmails
                                : [];
                              orderPaidNotificationEmails.push(values.email);
                              setFieldValue('orderPaidNotificationEmails', orderPaidNotificationEmails);
                              setFieldValue('email', '');
                            }
                          }}
                          size="medium"
                          variant="reversed"
                          className="no-min-width lp-w-full"
                          disabled={!values.email || (errors.email && touched.email) ? true : false}
                        >
                          <Icon name="Plus" />
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
                  <Button
                    size="medium"
                    variant="ghost"
                    className="no-min-width w-70"
                    type="button"
                    onClick={() => modalToggler(false)}
                    disabled={editRequestLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="primary"
                    className="no-min-width"
                    disabled={editRequestLoading}
                    isLoading={editRequestLoading}
                  >
                    Update
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default PaymentConfirmationsModal;
