import { handleResponse } from 'api/utils';
import { getAprilApiHost } from 'utils/env';
import getFetchOptions from 'utils/getFetchOptions';

import { UpdateMerchantRequest, UpdateMerchantResponse } from './merchants.types';

export const updateMerchant = async (
  merchantId: string,
  payload: UpdateMerchantRequest,
  bearerToken?: string,
): Promise<UpdateMerchantResponse> => {
  const options = await getFetchOptions('PATCH', { payload, bearerToken });
  return await fetch(`${await getAprilApiHost()}/merchants/${merchantId}`, options).then(handleResponse);
};
