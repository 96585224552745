import {
  getSettingBusinessPersonalInfo,
  getSettingBusinessProfile,
  getSettingBusinessRegistration,
  updateSettingBusinessPersonalDetail,
  updateSettingBusinessProfile,
} from 'api/merchant/dashboard/settings/business-profile';
import {
  BusinessInfoSettingsAPIResponse,
  PersonalInfoSettingsAPIResponse,
  PublicInfoAPIResponseType,
} from 'api/merchant/dashboard/settings/business-profile.types';
import { FormikProps } from 'formik';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/merchant/hooks';
import getErrorMessage from 'utils/getErrorMessage';

import BusinessProfileSettingView from './partials/BusinessProfileSettingView';
import { SettingBusinessProfileModal } from './types';

export const getProfileInitialState = () => {
  return {
    addressLine1: null,
    addressLine2: null,
    brandName: null,
    businessName: null,
    cardStatementName: null,
    city: null,
    contactEmail: null,
    phoneNo: null,
    postalCode: null,
    state: null,
    supportEmail: null,
    supportSite: null,
    website: null,
  };
};

export const getInitialBusinessInfo = (): BusinessInfoSettingsAPIResponse => ({
  typeOfProduct: '',
  taxId: '',
  businessType: '',
  taxCountry: 'AU',
  country: 'AU',
});

export const getInitialPersonalInfo = () => ({
  firstName: '',
  lastName: '',
  phoneNo: '',
});

function BusinessProfileSettingPage() {
  const [fetchBusinessProfileDetailLoader, setFetchBusinessProfileDetailLoader] = useState<boolean>(true);
  const [platformRegistrationLoader, setPlatformRegistrationLoader] = useState<boolean>(true);
  const [personalInfoLoader, setPersonalInfoLoader] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateErrorMsg, setUpdateErrorMsg] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [editRequestLoading, setEditRequestLoading] = useState<boolean>(false);
  const [businessProfileDetail, setBusinessProfileDetail] = useState<PublicInfoAPIResponseType>(
    getProfileInitialState(),
  );
  const [platformRegistationDetail, setPlatformRegistationDetail] = useState<BusinessInfoSettingsAPIResponse>(
    getInitialBusinessInfo(),
  );
  const [personalInfo, setPersonalInfo] = useState<PersonalInfoSettingsAPIResponse>(() => getInitialPersonalInfo());

  const formRef = useRef<FormikProps<SettingBusinessProfileModal>>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const { merchantId, apiBaseUri } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
  }));

  const submitHandler = async (values: SettingBusinessProfileModal, formObj: { resetForm: () => void }) => {
    try {
      setEditRequestLoading(true);
      setUpdateErrorMsg('');

      const { isManualEnterAddress, businessAddress, contactPerson, ...postData } = { ...values };

      await updateSettingBusinessProfile(apiBaseUri, merchantId, postData);
      await updateSettingBusinessPersonalDetail(apiBaseUri, merchantId, contactPerson);

      toast.success('Business profile updated successfully');
      setBusinessProfileDetail(values);
      formObj.resetForm();
      setEditModalOpen(false);
    } catch (error) {
      setUpdateErrorMsg(getErrorMessage(error));
    } finally {
      setEditRequestLoading(false);
    }
  };

  const fetchBusinessProfileDetail = useCallback(async () => {
    try {
      setFetchBusinessProfileDetailLoader(true);

      const response = await getSettingBusinessProfile(apiBaseUri, merchantId);
      setBusinessProfileDetail({ ...response, isManualEnterAddress: true, businessAddress: '' });
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchBusinessProfileDetailLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  const fetchPlatformBusinessRegistration = useCallback(async () => {
    try {
      setPlatformRegistrationLoader(true);

      const response = await getSettingBusinessRegistration(apiBaseUri, merchantId);
      setPlatformRegistationDetail(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPlatformRegistrationLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  const fetchPersonalInfoDetail = useCallback(async () => {
    try {
      setPersonalInfoLoader(true);

      const response = await getSettingBusinessPersonalInfo(apiBaseUri, merchantId);
      setPersonalInfo(response);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setPersonalInfoLoader(false);
    }
  }, [apiBaseUri, merchantId]);

  useEffect(() => {
    if (!apiBaseUri) {
      return;
    }

    setErrorMessage('');
    fetchBusinessProfileDetail();
    fetchPlatformBusinessRegistration();
    fetchPersonalInfoDetail();
  }, [apiBaseUri, merchantId, fetchBusinessProfileDetail, fetchPlatformBusinessRegistration, fetchPersonalInfoDetail]);

  useEffect(() => {
    if (!fetchBusinessProfileDetailLoader && !platformRegistrationLoader && !personalInfoLoader) {
      setIsPageLoading(false);
    }
  }, [fetchBusinessProfileDetailLoader, platformRegistrationLoader, personalInfoLoader, setIsPageLoading]);

  return (
    <MerchantDashboardLayout activeTab="settings" title="Business Settings">
      <BusinessProfileSettingView
        errorMsg={updateErrorMsg}
        editRequestLoading={editRequestLoading}
        businessProfileDetail={businessProfileDetail}
        personalInfo={personalInfo}
        onSubmitClick={submitHandler}
        formRef={formRef}
        setEditModalOpen={setEditModalOpen}
        editModalOpen={editModalOpen}
        platformRegistationDetail={platformRegistationDetail}
        isPageLoading={isPageLoading}
        errorMessage={errorMessage}
      />
    </MerchantDashboardLayout>
  );
}

export default BusinessProfileSettingPage;
