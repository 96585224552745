import { PaymentAPIResponseType } from 'api/merchant/dashboard/payments.types';
import * as s from 'assets/styles/Platforms.styles';
import { DateTime } from 'luxon';
import MerchantDefaultRoutes from 'pages/merchant/constants';
import { TableStyleSettings, TableThemeSettings } from 'pages/platform/constants';
import DashboardRoutesList from 'pages/platform/dashboard/constants';
import DataTable, { SortOrder, TableColumn, createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { useAppDispatch } from 'redux/platform/hooks';
import { setOpenPaymentFilterDropdown } from 'redux/platform/slice/paymentSlice';
import { toCurrency } from 'utils/currency';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';

import { Spinner } from '@limepayments/cosmic';

import PaymentRoutesList, { PaymentListStatusObj } from '../../constants';
import EmptyPaymentListComponent from './EmptyPaymentListComponent';
import FilterEmptyPaymentListComponent from './FilterEmptyPaymentListComponent';
import StatusTag from './StatusTagComponent';

const columns: TableColumn<PaymentAPIResponseType>[] = [
  {
    name: 'Amount',
    selector: (row: PaymentAPIResponseType) =>
      toCurrency(row.purchaseDetails.amount.minorCurrencyUnits, row.purchaseDetails.amount.currency),
    width: '100px',
    right: true,
  },
  {
    name: '',
    selector: (row: PaymentAPIResponseType) => row.purchaseDetails.amount.currency,
    width: '70px',
    style: {
      color: 'rgb(var(--lp-colors-neutral-400))',
    },
  },
  {
    name: 'Status',
    cell: (row: PaymentAPIResponseType) => (
      <StatusTag
        label={PaymentListStatusObj[row.purchaseDetails.status as keyof typeof PaymentListStatusObj].label}
        variant={PaymentListStatusObj[row.purchaseDetails.status as keyof typeof PaymentListStatusObj].varient}
        tooltipDirection="right"
        tooltipText={PaymentListStatusObj[row.purchaseDetails.status as keyof typeof PaymentListStatusObj].tooltipText}
        paymentTxnId={row.purchaseDetails.transactionId}
      />
    ),
    width: '120px',
  },
  {
    name: 'Customer',
    selector: (row: PaymentAPIResponseType) => row.customerDetails?.customerName ?? '',
  },
  {
    name: 'Payment option',
    selector: (row: PaymentAPIResponseType) =>
      row.purchaseDetails.payType === 'PayInFull'
        ? 'Full payment'
        : row.purchaseDetails.payType === 'PayPlan'
        ? 'Payment plan'
        : row.purchaseDetails.payType,
    width: '160px',
  },
  {
    name: 'Order ID',
    selector: (row: PaymentAPIResponseType) => row.purchaseDetails.referenceOrderId || row.purchaseDetails.orderId,
    minWidth: '200px',
  },
  {
    name: 'Transaction ID',
    selector: (row: PaymentAPIResponseType) => row.purchaseDetails.transactionId,
    minWidth: '200px',
  },
  {
    name: 'Ordered on',
    selector: (row: PaymentAPIResponseType) =>
      `${DateTime.fromISO(row.purchaseDetails.createdAt).toFormat('dd/LL/yyyy, HH:mm')}`,
    sortable: true,
    sortField: 'createdAt',
    minWidth: '130px',
  },
  {
    name: 'Available on',
    selector: (row: PaymentAPIResponseType) =>
      row.purchaseDetails.paidOn ? `${DateTime.fromISO(row.purchaseDetails.paidOn).toFormat('dd/LL/yyyy, HH:mm')}` : ``,
    minWidth: '130px',
  },
];

// createTheme creates a new theme named solarized that overrides the build in dark theme
createTheme('polarized', TableThemeSettings, 'dark');

interface Props {
  paymentList: Array<PaymentAPIResponseType>;
  fetchListLoader: boolean;
  totalRows: number;
  handlePerRowsChange: (newPerPage: number, page: number) => void;
  handlePageChange: (page: number) => void;
  limit: number;
  activePage: number;
  isFilterRequest: boolean;
  handleSort: (selectedColumn: TableColumn<PaymentAPIResponseType>, sortDirection: SortOrder) => void;
}

function MerchantsDataTable({
  paymentList,
  totalRows,
  limit,
  fetchListLoader,
  handlePageChange,
  handlePerRowsChange,
  activePage,
  handleSort,
  isFilterRequest,
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { merchantName } = useAppSelector((state) => ({
    merchantName: state.config.merchantName,
  }));

  return (
    <s.MerchantPaymentsTable>
      <DataTable
        pagination
        responsive
        columns={columns}
        data={paymentList}
        customStyles={TableStyleSettings}
        theme="polarized"
        className=""
        progressPending={fetchListLoader}
        progressComponent={
          <div className="transaction-empty">
            <div className="transaction-empty-inner">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          </div>
        }
        noDataComponent={
          isFilterRequest && paymentList.length < 1 ? (
            <FilterEmptyPaymentListComponent toggleFilter={() => dispatch(setOpenPaymentFilterDropdown(true))} />
          ) : (
            <EmptyPaymentListComponent
              title={'No payments to display'}
              body={'Payments will appear here when they have been initiated.'}
            />
          )
        }
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={limit}
        paginationDefaultPage={activePage}
        onRowClicked={(row) =>
          navigate(
            `${getMerchantBaseUrl(merchantName)}/${MerchantDefaultRoutes.DASHBOARD_ROUTE}/${
              DashboardRoutesList.PAYMENT_ROUTE
            }/${PaymentRoutesList.PAYMENT_DETAILS}?txnId=${row.purchaseDetails.transactionId}`,
          )
        }
        onSort={handleSort}
        sortServer
        persistTableHead
        defaultSortFieldId={8}
        defaultSortAsc={false}
        paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        pointerOnHover={true}
      />
    </s.MerchantPaymentsTable>
  );
}

export default MerchantsDataTable;
