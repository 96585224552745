import { handleResponse } from 'api/utils';
import getFetchOptions from 'utils/getFetchOptions';

import {
  BusinessInfoSettingsAPIResponse,
  PersonalInfoSettingsAPIResponse,
  PublicInfoAPIResponseType,
} from './business-profile.types';

export const getSettingBusinessProfile = async (
  host: string,
  merchantId: string,
): Promise<PublicInfoAPIResponseType> => {
  const options = await getFetchOptions();

  return await fetch(`${host}/merchants/${merchantId}/settings/public-info`, options).then(handleResponse);
};

export const getSettingBusinessRegistration = async (
  host: string,
  merchantId: string,
): Promise<BusinessInfoSettingsAPIResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/settings/business`, options).then(handleResponse);
};

export const getSettingBusinessPersonalInfo = async (
  host: string,
  merchantId: string,
): Promise<PersonalInfoSettingsAPIResponse> => {
  const options = await getFetchOptions();
  return await fetch(`${host}/merchants/${merchantId}/settings/personal`, options).then(handleResponse);
};

export const updateSettingBusinessProfile = async (
  host: string,
  merchantId: string,
  payload: PublicInfoAPIResponseType,
): Promise<void> => {
  const options = await getFetchOptions('POST', { payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/public-info`, options).then(handleResponse);
};

export const updateSettingBusinessPersonalDetail = async (
  host: string,
  merchantId: string,
  payload: PersonalInfoSettingsAPIResponse,
): Promise<void> => {
  const options = await getFetchOptions('PATCH', { payload });

  return await fetch(`${host}/merchants/${merchantId}/settings/personal`, options).then(handleResponse);
};
