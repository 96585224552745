import { IApiKeyObj } from 'api/merchant/dashboard/settings/api-keys.types';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { Fragment } from 'react';

import { Button, Heading, Icon, Link, Message, Spinner, Text } from '@limepayments/cosmic';

import * as s from '../../../../../../assets/styles/Platforms.styles';
import SettingRoutesList from '../../constants';
// Import Static UIs
import SettingsSidebar from '../../partials/SettingsSidebar';

export interface Props {
  copyToClipBoard: (_text: string, isPublicButtonClicked: boolean) => void;
  secrectTokenCopied: boolean;
  publicTokenCopied: boolean;
  errorMessage: string;
  isPageLoading: boolean;
  apiKeysObj: IApiKeyObj | null;
  storybookCall?: boolean;
}

function APIKeysView({
  copyToClipBoard,
  apiKeysObj,
  isPageLoading,
  secrectTokenCopied,
  publicTokenCopied,
  errorMessage,
  storybookCall,
}: Props) {
  return (
    <div className="content-wrap-inner with-gutter">
      <div className="lp-w-full lp-flex">
        <div className="payment-sidebar">
          <SettingsSidebar activeTab={SettingRoutesList.SETTINGS_API_KEYS} />
        </div>

        <div className="payment-content-area">
          {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

          {isPageLoading && (
            <div className="spinner-wrapper">
              <Spinner variant="simple" isVisible label="Loading..." />
            </div>
          )}
          {!isPageLoading && apiKeysObj && (
            <Fragment>
              <div className="lp-w-full lp-flex lp-justify-between sm-lp-flex-col mb-32">
                <Heading alignment="left" tagName="h2" variant="sm">
                  API keys
                </Heading>
              </div>

              <s.MerchantsData className="bt-1">
                <div className="lp-w-full lp-flex lp-align-start lp-justify-between mb-32">
                  <div>
                    <Heading alignment="left" tagName="h2" variant="xs">
                      Your API keys
                    </Heading>
                  </div>
                  <div className="ml-auto">
                    <div className="lp-message">
                      <Message dismissalType="none" type="inline" variant="information">
                        <Text variant="caption" className="mb-0 mt-4">
                          Need help setting up? Read our
                          <strong className="ml-4">
                            <Link onClick={function noRefCheck() {}} target="_self">
                              instillation guides
                            </Link>
                          </strong>
                          .
                        </Text>
                      </Message>
                    </div>
                  </div>
                </div>

                <div className="lp-w-full mb-32">
                  <div className="lp-flex lp-align-center ">
                    <Text variant="caption" className="text-dark-50 m-0">
                      Public key
                    </Text>
                    <span
                      data-balloon="Used for backend or frontend calls to query or execute actions on behalf of a merchant in a public fashion."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-center">
                    <Text variant="body-3" className="mt-0 mb-4">
                      {apiKeysObj.publicToken}
                    </Text>
                    <Button
                      size="small"
                      variant="reversed"
                      onClick={() => copyToClipBoard(apiKeysObj.publicToken, true)}
                      className="btn-copy top-1px ml-4"
                    >
                      <Icon name="Copy" className="ml-0 mr-4" />
                      {publicTokenCopied ? `Copied` : `Copy to clipboard`}
                    </Button>
                  </div>
                  <Text variant="legal" className="text-dark-50 mt-0">
                    Created{' '}
                    {storybookCall
                      ? `31/03/2021 08:10`
                      : DateTime.fromISO(apiKeysObj.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                  </Text>
                </div>

                <div className="lp-w-full mb-32">
                  <div className="lp-flex lp-align-center ">
                    <Text variant="caption" className="text-dark-50 m-0">
                      Private key
                    </Text>
                    <span
                      data-balloon="Used for backend server to server calls only. You mustn't share your private key. If the key is compromised, please contact support immediately."
                      data-balloon-pos="up"
                      data-balloon-length="large"
                      className="lp-flex lp-align-center lp-justify-center size-11"
                    >
                      <Icon name="Info" />
                    </span>
                  </div>
                  <div className="lp-w-full lp-flex lp-align-center">
                    <Text variant="body-3" className="text-purple mt-0 mb-4">
                      *********************************************************
                    </Text>
                    <Button
                      size="small"
                      variant="reversed"
                      onClick={() => copyToClipBoard(apiKeysObj.secretToken, false)}
                      className="btn-copy top-1px ml-4"
                    >
                      <Icon name="Copy" className="ml-0 mr-4" />
                      {secrectTokenCopied ? `Copied` : `Copy to clipboard`}
                    </Button>
                  </div>
                  <Text variant="legal" className="text-dark-50 mt-0">
                    Created{' '}
                    {storybookCall
                      ? `31/03/2021 08:10`
                      : DateTime.fromISO(apiKeysObj.createdAt).toFormat('dd/MM/yyyy HH:mm')}
                  </Text>
                </div>
              </s.MerchantsData>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default APIKeysView;
