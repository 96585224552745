import * as s from 'assets/styles/Platforms.styles';
import AuthRoutesList from 'pages/merchant/auth/constants';
import DashboardRoutesList from 'pages/merchant/dashboard/constants';
import PaymentRoutesList from 'pages/merchant/dashboard/payment/constants';
import PayoutRoutesList from 'pages/merchant/dashboard/payout/constants';
import SettingRoutesList from 'pages/merchant/dashboard/setting/constants';
import VirtualTerminalRoutesList from 'pages/merchant/dashboard/virtual-terminal/constants';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/merchant/hooks';
import { getMerchantBaseUrl } from 'utils/getBaseUrl';
import { showZendeskWidget } from 'utils/zendesk';

import { Button, Heading, Icon } from '@limepayments/cosmic';

interface Props {
  activeTab?: string;
}

const ToggleSidebarComponent = ({ activeTab }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setIsopen] = useState<boolean>(false);
  const [isInnerOpen, setIsInneropen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string>('');
  const [pathElements, setPathElements] = useState<Array<string>>([]);
  const { merchantEmail, merchantName } = useAppSelector((state) => ({
    merchantEmail: state.config.merchantEmail,
    merchantName: state.config.merchantName,
  }));

  const toggleSidebar = (overlayClicked?: boolean) => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    if (!isOpen) {
      const pathElements = pathname.split('/');
      setPathElements(pathElements);
      if (
        pathElements &&
        pathElements.length >= 6 &&
        (pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE || pathElements[4] === DashboardRoutesList.PAYOUT_ROUTE)
      ) {
        setActiveSection(pathElements[4]);
        setIsInneropen(true);
      }
    }

    if (overlayClicked) {
      setIsInneropen(false);
    }
  };

  const toggleInnerSidebar = (section: string) => {
    setActiveSection(section);
    isInnerOpen === true ? setIsInneropen(false) : setIsInneropen(true);
  };

  return (
    <>
      <div className="sidebar-menu-wrap">
        {/* TODO: search
        <s.HeaderSearch className="btn btn-primary header-icon" onClick={showZendeskWidget}>
          <Icon name="Search" />
        </s.HeaderSearch> */}
        <s.HeaderHelp className="btn btn-primary header-icon" onClick={showZendeskWidget}>
          <Icon name="Help" />
        </s.HeaderHelp>
        <s.HeaderToggle className="btn btn-primary header-icon" onClick={() => toggleSidebar()}>
          <Icon name="Ellipse" />
        </s.HeaderToggle>
        <div className={`sidebar-menu ${isOpen === true ? 'is-active' : ''}`}>
          <div className="sidebar-menu-inner">
            <div className="sd-header">
              <button className="sd-close" onClick={() => toggleSidebar()}>
                <Icon name="Cross" />
              </button>
            </div>
            <div className="sd-body">
              <ul>
                <li>
                  <button
                    className={`sd-link ${activeTab === DashboardRoutesList.PAYMENT_ROUTE ? ` is-active` : ``}`}
                    onClick={() =>
                      navigate(
                        `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.PAYMENT_ROUTE}/${
                          PaymentRoutesList.PAYMENT_LANDING
                        }`,
                      )
                    }
                  >
                    <span>Payments</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`sd-link ${
                      activeTab === DashboardRoutesList.VIRTUAL_TERMINAL_ROUTE ? ` is-active` : ``
                    }`}
                    onClick={() =>
                      navigate(
                        `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.VIRTUAL_TERMINAL_ROUTE}/${
                          VirtualTerminalRoutesList.VIRTUAL_TERMINAL_LANDING
                        }`,
                      )
                    }
                  >
                    <span>Virtual Terminal</span>
                  </button>
                </li>
                <li>
                  <button
                    className={`sd-link ${activeTab === DashboardRoutesList.PAYOUT_ROUTE ? ` is-active` : ``}`}
                    onClick={() => toggleInnerSidebar(DashboardRoutesList.PAYOUT_ROUTE)}
                  >
                    <span>Payouts</span>
                    <Icon name="ChevronRight" />
                  </button>
                </li>
                {/* <li>
                  <button className={`sd-link ${activeTab === MERCHANT_MENU ? ` is-active` : ``}`}>
                    <span>Overview</span>
                  </button>
                </li> */}
                <li className="divider"></li>
                <li>
                  <button
                    className={`sd-link ${activeTab === DashboardRoutesList.SETTINGS_ROUTE ? ` is-active` : ``}`}
                    onClick={() => toggleInnerSidebar(DashboardRoutesList.SETTINGS_ROUTE)}
                  >
                    <span className="lp-flex lp-align-center">
                      <Icon name="Settings" className="mr-8" />
                      <span>Settings</span>
                    </span>
                    <Icon name="ChevronRight" />
                  </button>
                </li>
                {/* <li>
                  <a href={config.HELP_URL} target="_blank" rel="noreferrer" className="sd-link">
                    <span className="lp-flex lp-align-center">
                      <Icon name="Help" className="mr-8" />
                      <span>Help</span>
                    </span>
                  </a>
                </li> */}
              </ul>

              {/* Sub Child Menus */}
              <div className={`sd-submenu ${isInnerOpen === true ? 'navigation-active' : ''}`}>
                <div className="sidebar-menu-inner">
                  <div className="sd-header">
                    <Button size="small" onClick={() => toggleInnerSidebar('')} variant="ghost" className="sd-back">
                      <Icon name="ArrowLeft" />
                    </Button>
                    <span>{activeSection === DashboardRoutesList.PAYOUT_ROUTE ? `Payouts` : `Settings`}</span>
                    <button type="button" className="sd-close2" onClick={() => toggleInnerSidebar('')}>
                      <Icon name="Cross" />
                    </button>
                  </div>

                  {activeSection === DashboardRoutesList.PAYOUT_ROUTE && (
                    <div className="sd-body">
                      <ul>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.PAYOUT_ROUTE}/${
                                PayoutRoutesList.PAYOUT_LANDING
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.PAYOUT_ROUTE &&
                              pathElements[5] === PayoutRoutesList.PAYOUT_LANDING
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Payouts
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.PAYOUT_ROUTE}/${
                                DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE
                              }/${PayoutRoutesList.PAYOUT_BREAKDOWN}`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.PAYOUT_ROUTE &&
                              pathElements[5] === DashboardRoutesList.PAYOUT_BREAKDOWN_ROUTE
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Payout breakdown
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}

                  {/* Settings Panel */}
                  {activeSection === DashboardRoutesList.SETTINGS_ROUTE && (
                    <div className="sd-body">
                      <Heading alignment="left" tagName="h2" variant="xs" className="submenu-title fw-medium mb-0">
                        My account
                      </Heading>
                      <ul className="mb-28">
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_PROFILE
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_PROFILE
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            My profile
                          </button>
                        </li>
                      </ul>

                      <Heading alignment="left" tagName="h2" variant="xs" className="submenu-title fw-medium mb-0">
                        Settings
                      </Heading>
                      <ul>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_BUSINESS_PROFILE
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_BUSINESS_PROFILE
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Business profile
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_BRANDING
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_BRANDING
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Branding
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_PAYMENT
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_PAYMENT
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Payments
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_PAYOUTS
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_PAYOUTS
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            Payouts
                          </button>
                        </li>
                        <li
                          onClick={() =>
                            navigate(
                              `${getMerchantBaseUrl(merchantName)}/dashboard/${DashboardRoutesList.SETTINGS_ROUTE}/${
                                SettingRoutesList.SETTINGS_API_KEYS
                              }`,
                            )
                          }
                        >
                          <button
                            className={`sd-link ${
                              pathElements[4] === DashboardRoutesList.SETTINGS_ROUTE &&
                              pathElements[5] === SettingRoutesList.SETTINGS_API_KEYS
                                ? 'is-active'
                                : ''
                            }`}
                          >
                            API keys
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {/* Sub Child Menus */}
            </div>

            <div className="sd-footer">
              <ul>
                <li className="is-active">{merchantEmail}</li>
                {/* TODO: do it later */}
                {/* <li className="fw-regular">Admin</li> */}
                <li className="mt-12">
                  <button
                    onClick={() => navigate(`${getMerchantBaseUrl(merchantName)}/${AuthRoutesList.LOGOUT_PAGE}`)}
                    className="sd-link"
                  >
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={`sidebar-menu-overlay ${isOpen === true ? 'is-enabled' : ''}`}
          onClick={() => toggleSidebar(true)}
        ></div>
      </div>
    </>
  );
};

export default ToggleSidebarComponent;
