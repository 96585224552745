import * as s from 'assets/styles/Platforms.styles';

import { Button, Heading, Icon, Link, Modal, Text } from '@limepayments/cosmic';

import { APPLE_DOMAIN_ASSOCIATION_FILE_URL } from '../../constants';

interface Props {
  isOpen: boolean;
  modalToggler: (isOpen: boolean) => void;
}

function ApplePayModal({ isOpen, modalToggler }: Props) {
  return (
    <Modal isOpen={isOpen} className="modal-medium update-pass-modal">
      <s.Wrapper className="">
        <div className="modal-main p-24">
          <div className="modal-header">
            <div className="content-main bb-1">
              <Heading alignment="center" tagName="h2" variant="xs" className="mt-12 mb-16 lp-justify-center lp-flex">
                Apple Pay™
              </Heading>
            </div>
          </div>
          <div className="modal-content p-28 sm-p-0 sm-mt-24">
            <div className="lp-w-full pb-24 bb-1 mb-24">
              <div className="lp-w-full mb-24">
                <Text variant="body-2" className="text-dark-50 mb-0" isEmphasised>
                  You are required to host a domain verification file for each registered domain at the following path:
                </Text>
              </div>

              <div className="lp-w-full well mb-24">
                <Text variant="body-3" className="m-0">
                  https://[DOMAIN_NAME]/.well-known/apple-developer-merchantid-domain-association
                </Text>
              </div>

              <div className="lp-w-full mb-16">
                <Text variant="caption" className="text-dark-50 m-0">
                  For example, if the checkout is hosted at:
                </Text>
                <Text variant="caption" className="mt-4 mb-0">
                  https://store.example.com
                </Text>
              </div>
              <div className="lp-w-full mb-24">
                <Text variant="caption" className="text-dark-50 m-0">
                  The domain verification file would need to be accessible at:
                </Text>
                <Text variant="caption" className="mt-4 mb-0">
                  https://store.example.com/.well-known/apple-developer-merchantid-domain-association.
                </Text>
              </div>
            </div>

            <div className="lp-w-full mb-12">
              <div>
                <Text variant="body-3" className="text-dark-50 mb-16">
                  Domain verification files are <strong>not</strong> required for the Virtual Terminal.
                </Text>
                <Text variant="body-3" className="text-dark-50 mt-0 mb-16">
                  Click the button below to download the domain verification file or visit{' '}
                  <Link href={APPLE_DOMAIN_ASSOCIATION_FILE_URL} size="small" target="_blank" className="fw-medium">
                    apple-developer-merchantid-domain-association
                  </Link>
                  .
                </Text>
                <Text variant="body-3" className="text-dark-50 mt-0 mb-0">
                  <strong>Having trouble setting up?</strong> Please read our{' '}
                  <Link
                    href="https://docs.limepay.com.au/developer-portal/apple-pay/"
                    size="small"
                    target="_blank"
                    className="fw-medium"
                  >
                    developer documentation
                  </Link>{' '}
                  or visit our{' '}
                  <Link
                    href="https://limepay.zendesk.com/hc/en-us/articles/5004612298511-Digital-Wallets-"
                    size="small"
                    target="_blank"
                    className="fw-medium"
                  >
                    support hub
                  </Link>
                  .
                </Text>
              </div>
            </div>
          </div>

          <div className="modal-footer lp-flex lp-justify-end sm-mt-24">
            <Button
              size="medium"
              variant="ghost"
              className="no-min-width w-70"
              // disabled
              onClick={() => modalToggler(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                window.open(APPLE_DOMAIN_ASSOCIATION_FILE_URL, '_blank');
              }}
              size="medium"
              variant="primary"
              className="no-min-width"
              // disabled
            >
              <Icon name="Download" className="ml-0 mr-8" />
              Domain verification file
            </Button>
          </div>
        </div>
      </s.Wrapper>
    </Modal>
  );
}

export default ApplePayModal;
