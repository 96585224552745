import { getMerchantBalances, getMerchantPayoutsList } from 'api/merchant/dashboard/payouts';
import { PayoutAPIResponseType } from 'api/merchant/dashboard/payouts.types';
import { GetMerchantBalancesResponse } from 'api/merchant/dashboard/settlement.types';
import MerchantDashboardLayout from 'layouts/merchant-dashboard/MerchantDashboardLayout';
import { DateTime } from 'luxon';
import ErrorComponent from 'pages/common/error/ErrorComponent';
import { LISTING_RECORDS_PER_PAGE, MAX_FILTERING_DAYS } from 'pages/platform/constants';
import { useCallback, useEffect, useState } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'redux/merchant/hooks';
import { setOpenPaymentFilterDropdown } from 'redux/merchant/slice/paymentSlice';
import getErrorMessage from 'utils/getErrorMessage';

import { Spinner } from '@limepayments/cosmic';

import DashboardRoutesList from '../../constants';
import PayoutBalancePage from './partials/PayoutBalancePage';
import { IPayoutFilterParentObj, IPayoutFilterValuesObj } from './types';

const getFilterInitialState = () => {
  return {
    statusName: '',
    startDate: '',
    endDate: '',
    filterPopupOpen: false,
    isFilterRequest: false,
    merchantId: '',
    orderId: '',
    txnId: '',
    amount: '',
  };
};

function PayoutPage() {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(LISTING_RECORDS_PER_PAGE);
  const [fetchListLoader, setFetchListLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [balances, setBalances] = useState<GetMerchantBalancesResponse['balances']>();
  const [payoutList, setPayoutList] = useState<Array<PayoutAPIResponseType>>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [totalRows, setTotalRows] = useState<number>(0);

  const [filterObj, setFilterObj] = useState<IPayoutFilterParentObj>(getFilterInitialState());
  const [sortBy, setSortBy] = useState<string>('-createdAt');

  const { merchantId, apiBaseUri, openPaymentFilterDropdown } = useAppSelector((state) => ({
    merchantId: state.config.merchantId,
    apiBaseUri: state.config.apiBaseUri,
    openPaymentFilterDropdown: state.payment.openPaymentFilterDropdown,
  }));

  const handleSort = (selectedColumn: TableColumn<PayoutAPIResponseType>, sortDirection: SortOrder) => {
    setActivePage(1);
    setTotalRows(0);
    setSortBy(
      selectedColumn.sortField ? `${sortDirection === 'asc' ? `` : `-`}${selectedColumn.sortField}` : '-createdAt',
    );
  };

  const filterRecordHandler = (obj: IPayoutFilterValuesObj) => {
    let fromDate = '';
    let toDate = '';

    if (obj.dateTypeCompareValue) {
      if (obj.dateTypeCompareValue === 'is_in_the_last') {
        if (obj.dateTypeFilterValue === 'days') {
          fromDate = `${DateTime.utc()
            .minus({ days: parseInt(obj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }

        if (obj.dateTypeFilterValue === 'month') {
          fromDate = `${DateTime.utc()
            .minus({ months: parseInt(obj.dateValue) })
            .startOf('day')
            .toISO()}`;
          toDate = `${DateTime.utc().endOf('day').toISO()}`;
        }
      }

      if (obj.dateTypeCompareValue === 'is_equal_to' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_between' && obj.dateRangeValue) {
        const fromDateObj = new Date(obj.dateRangeValue[0]);
        const toDateObj = new Date(obj.dateRangeValue[1]);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(toDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_after' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);

        fromDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().endOf('day').toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .plus({ months: MAX_FILTERING_DAYS })
          .endOf('day')
          .toISO()}`;
      }

      if (obj.dateTypeCompareValue === 'is_before' && obj.dateSingleValue) {
        const fromDateObj = new Date(obj.dateSingleValue);
        fromDate = `${DateTime.fromISO(fromDateObj.toISOString())
          .toUTC()
          .minus({ months: MAX_FILTERING_DAYS })
          .startOf('day')
          .toISO()}`;
        toDate = `${DateTime.fromISO(fromDateObj.toISOString()).toUTC().startOf('day').toISO()}`;
      }
    }

    setActivePage(1);
    setTotalRows(0);
    setFilterObj((prevState) => ({
      ...prevState,
      statusName: obj.status,
      startDate: encodeURIComponent(fromDate),
      endDate: encodeURIComponent(toDate),
      isFilterRequest: true,
      merchantId: obj.merchantId,
      orderId: obj.orderId,
      txnId: obj.txnId,
      amount: obj.amount,
    }));
  };

  const toggleFilter = useCallback((val: boolean) => {
    if (val) {
      setActivePage(1);
      setTotalRows(0);
      setFilterObj(getFilterInitialState());
    } else {
      setFilterObj((prevState) => ({
        ...prevState,
        filterPopupOpen: !prevState.filterPopupOpen,
      }));
    }
  }, []);

  const fetchPaymentsList = useCallback(async () => {
    try {
      setFetchListLoader(true);
      setErrorMessage('');

      let queryString = `page=${activePage}`;

      if (filterObj.merchantId) {
        queryString += `&merchantId=${filterObj.merchantId}`;
      }

      if (filterObj.orderId) {
        queryString += `&orderId=${filterObj.orderId}`;
      }

      if (filterObj.statusName) {
        queryString += `&status=${filterObj.statusName}`;
      }

      if (filterObj.txnId) {
        queryString += `&transactionId=${filterObj.txnId}`;
      }

      if (limit) {
        queryString += `&limit=${limit}`;
      }

      if (sortBy) {
        queryString += `&sort=${sortBy}`;
      }

      if (filterObj.startDate) {
        queryString += `&from=${filterObj.startDate}`;
      }

      if (filterObj.endDate) {
        queryString += `&to=${filterObj.endDate}`;
      }

      if (filterObj.amount) {
        queryString += `&amount=${parseFloat(filterObj.amount) * 100}`; // convert into cents
      }

      console.log(queryString);

      const [{ balances }, { items, totalRecords }] = await Promise.all([
        getMerchantBalances(merchantId),
        getMerchantPayoutsList(queryString),
      ]);

      setBalances(balances);
      setPayoutList(items);
      setTotalRows(totalRecords);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setFetchListLoader(false);
    }
  }, [
    merchantId,
    activePage,
    limit,
    sortBy,
    filterObj.startDate,
    filterObj.endDate,
    filterObj.statusName,
    filterObj.merchantId,
    filterObj.orderId,
    filterObj.txnId,
    filterObj.amount,
  ]);

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setActivePage(page);
  };

  useEffect(() => {
    setErrorMessage('');
    fetchPaymentsList();
  }, [
    apiBaseUri,
    merchantId,
    sortBy,
    limit,
    activePage,
    fetchPaymentsList,
    filterObj.startDate,
    filterObj.endDate,
    filterObj.statusName,
    filterObj.merchantId,
    filterObj.orderId,
    filterObj.txnId,
  ]);

  useEffect(() => {
    if (!fetchListLoader) {
      setIsPageLoading(false);
    }
  }, [setIsPageLoading, fetchListLoader]);

  useEffect(() => {
    if (openPaymentFilterDropdown) {
      toggleFilter(false);
      setTimeout(() => {
        dispatch(setOpenPaymentFilterDropdown(false));
      }, 2000);
    }
  }, [openPaymentFilterDropdown, toggleFilter, dispatch]);

  return (
    <MerchantDashboardLayout activeTab={DashboardRoutesList.PAYOUT_ROUTE} title="Payout">
      {!isPageLoading && errorMessage.length ? <ErrorComponent bodyText={errorMessage} /> : null}

      {isPageLoading && (
        <div className="spinner-wrapper">
          <Spinner variant="simple" isVisible label="Loading..." />
        </div>
      )}

      {!isPageLoading && (
        <PayoutBalancePage
          balances={balances}
          payoutList={payoutList}
          fetchListLoader={fetchListLoader}
          totalRows={totalRows}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          limit={limit}
          toggleFilter={toggleFilter}
          filterRecordHandler={filterRecordHandler}
          filterValuesObj={filterObj}
          activePage={activePage}
          handleSort={handleSort}
        />
      )}
    </MerchantDashboardLayout>
  );
}

export default PayoutPage;
